import React from 'react'
import { graphql, Link } from 'gatsby'
// eslint-disable-next-line
// import particlesJs from 'particles.js'
import PropTypes from 'prop-types'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'
import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box,
  // Level,
  // Tile,
  Columns
} from 'react-bulma-components'

import Image from 'gatsby-image'

import './styles/index.scss'

import BusinessIcon from '../../content/assets/icons/business.svg'
import ThumbsUpIcon from '../../content/assets/icons/thumbs-up.svg'
import CheckmarkIcon from '../../content/assets/icons/checkmark.svg'
import ConsultationIcon from '../../content/assets/icons/consultation.svg'
import AppleIcon from '../../content/assets/icons/apple.svg'
import AdIcon from '../../content/assets/icons/ad.svg'
import AnalyticsIcon from '../../content/assets/icons/analytical-products.svg'
import FintechIcon from '../../content/assets/icons/fintech.svg'
import CloudIcon from '../../content/assets/icons/cloud.svg'
import SecurityIcon from '../../content/assets/icons/security.svg'

import ArtDirectedDomainsBackground from '../components/ArtDirectedDomainsBackground'
import ArtHeaderBackground from '../components/ArtHeaderBackground'
import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

import { Desktop, Tablet, Mobile } from '../utils/presets'

// eslint-disable-next-line
const IconBox = ({ children, ...props }) =>
  (
  <div
    css={{
      display: 'flex',
      backgroundColor: '#F1F2FD',
      borderRadius: 4,
      margin: '.1rem 1rem .1rem .5rem',
      padding: '.5rem',
      width: 60
    }}
    {...props}
  >
    {children}
  </div>
)

// eslint-disable-next-line
const ResponsiveBox = ({ children }) => (
  <Box>
    <div
      css={{
        margin: '0 auto',
        [Mobile]: {
          display: 'flex',
          justifyContent: 'center'
        },
        [Tablet]: {
          display: 'flex',
          justifyContent: 'center'
        },
        [Desktop]: {
          display: 'inherit',
          justifyContent: 'inherit'
        }
      }}
    >
      {children}
    </div>
  </Box>
)

// eslint-disable-next-line
const WhyUSBoxContent = ({ title, subtitle }) => (
  <div css={{ display: 'flex', flexDirection: 'column', marginTop: '0.2rem' }}>
    <Heading size={6} renderAs="h3">
      {title}
    </Heading>
    <Heading subtitle size={7} renderAs="h5">
      {subtitle}
    </Heading>
  </div>
)

class IndexPage extends React.Component {
  getImage(imageName) {
    return this.props.data[imageName].childImageSharp.fluid
  }
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const boxStyles = {
      backgroundColor: '#fff',
      borderRadius: '6px',
      boxShadow:
        '0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)',
      transitionDuration: '200ms',
      transitionProperty: 'box-shadow,transform',
      willChange: 'box-shadow,transform',
      userSelect: 'none',
      padding: '1rem 1.5rem',
      [Desktop]: {
        marginRight: '1.5rem'
      },
      marginBottom: '1.5rem'
    }

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO title="Mobile, AI and Data solutions providers for market leaders" />
        <Hero size="large">
          <Hero.Body className="is-paddingless">
            <ArtHeaderBackground style={{ height: 500 }}>
              <div
                css={{
                  position: 'absolute',
                  top: '25%',
                  left: '10%'
                }}
              >
                <Heading size={1}>Tangibly.tech</Heading>
                <Heading
                  subtitle
                  size={4}
                  renderAs="h2"
                  className="responsive-heading"
                  css={{ [Tablet]: { width: 450, marginTop: '1rem' } }}
                >
                  Turn ideas to apps and algorythms that add value to your
                  business
                </Heading>
                <Button
                  color="primary"
                  style={{
                    display: 'flex',
                    fontWeight: 'bold'
                  }}
                  onClick={this.openMailTo}
                >
                  CONTACT US
                </Button>
              </div>
            </ArtHeaderBackground>
          </Hero.Body>
        </Hero>
        <Hero>
          <Hero.Body>
            <Container>
              <Heading
                size={2}
                renderAs="p"
                className="has-text-centered is-size-6-mobile"
              >
                Why us
              </Heading>
              <Columns breakpoint="desktop" style={{ marginTop: '1rem' }}>
                <Columns.Column
                  tablet={{
                    offset: 0
                  }}
                  desktop={{
                    size: 3,
                    offset: 3
                  }}
                  className="custom-column"
                  css={boxStyles}
                >
                  <div css={{ display: 'flex' }}>
                    <IconBox>
                      <BusinessIcon />
                    </IconBox>
                    <WhyUSBoxContent
                      title="Business oriented"
                      subtitle="We understand your business needs and provide value"
                    />
                  </div>
                </Columns.Column>
                <Columns.Column
                  className="custom-column"
                  desktop={{
                    size: 3
                  }}
                  css={boxStyles}
                >
                  <div css={{ display: 'flex' }}>
                    <IconBox>
                      <ThumbsUpIcon />
                    </IconBox>
                    <WhyUSBoxContent
                      title="Highly skilled"
                      subtitle="Experienced team with proven success records"
                    />
                  </div>
                </Columns.Column>
                <Columns.Column
                  tablet={{
                    offset: 0
                  }}
                  desktop={{
                    size: 3,
                    offset: 3
                  }}
                  className="custom-column"
                  css={boxStyles}
                >
                  <div css={{ display: 'flex' }}>
                    <IconBox>
                      <CheckmarkIcon />
                    </IconBox>
                    <WhyUSBoxContent
                      title="Full cycle services"
                      subtitle="Software solutions from scratch"
                    />
                  </div>
                </Columns.Column>
                <Columns.Column
                  desktop={{
                    size: 3
                  }}
                  className="custom-column"
                  css={boxStyles}
                >
                  <div css={{ display: 'flex' }}>
                    <IconBox>
                      <ConsultationIcon />
                    </IconBox>
                    <WhyUSBoxContent
                      title="Tech Consultancy"
                      subtitle="IT and product consulting of any complexity"
                    />
                  </div>
                </Columns.Column>
              </Columns>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="medium">
          <Hero.Body style={{ padding: '1.5rem .75rem', paddingTop: '1rem' }}>
            <Container>
              <ArtDirectedDomainsBackground
                style={{
                  [Desktop]: {
                    height: 600,
                    paddingTop: '4rem'
                  },
                  [Tablet]: {
                    height: 900,
                    paddingTop: '0rem'
                  }
                }}
              >
                <Columns
                  breakpoint="desktop"
                  className="custom-columns"
                  css={{
                    [Desktop]: {
                      marginBottom: '.75rem !important'
                    }
                  }}
                >
                  <Columns.Column>
                    <Columns breakpoint="desktop">
                      <Columns.Column
                        className="custom-col"
                        css={{
                          [Desktop]: {
                            paddingTop: '4rem !important'
                          }
                        }}
                      >
                        <ResponsiveBox>
                          <IconBox>
                            <AppleIcon />
                          </IconBox>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Mac OS/iOS <br /> Apps
                          </Heading>
                        </ResponsiveBox>
                        <ResponsiveBox>
                          <IconBox>
                            <AdIcon />
                          </IconBox>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Online <br /> Advertising
                          </Heading>
                        </ResponsiveBox>
                      </Columns.Column>
                      <Columns.Column>
                        <ResponsiveBox>
                          <IconBox>
                            <AnalyticsIcon />
                          </IconBox>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Analytics <br /> products {'\u00A0'}
                          </Heading>
                        </ResponsiveBox>
                        <ResponsiveBox>
                          <IconBox>
                            <FintechIcon />
                          </IconBox>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Fintech <br /> Solutions {'\u00A0'}
                          </Heading>
                        </ResponsiveBox>
                      </Columns.Column>
                      <Columns.Column
                        className="custom-col"
                        css={{
                          [Desktop]: {
                            paddingTop: '4rem !important'
                          }
                        }}
                      >
                        <ResponsiveBox>
                          <IconBox>
                            <CloudIcon />
                          </IconBox>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            SAAS <br /> products
                          </Heading>
                        </ResponsiveBox>
                        <ResponsiveBox>
                          <IconBox>
                            <SecurityIcon />
                          </IconBox>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Security <br /> Software
                          </Heading>
                        </ResponsiveBox>
                      </Columns.Column>
                    </Columns>
                  </Columns.Column>
                  <Columns.Column>
                    <Columns breakpoint="tablet">
                      <Columns.Column size={12}>
                        <Section>
                          <Heading size={1} renderAs="h2">
                            Our domains
                          </Heading>
                          <Heading subtitle renderAs="h2">
                            We dive deeply in each problem figuring out the most
                            efficient elegant solution.
                          </Heading>
                          <Link to="/stack" className="is-primary button">
                            TECHNOLOGY STACK
                          </Link>
                        </Section>
                      </Columns.Column>
                    </Columns>
                  </Columns.Column>
                </Columns>
              </ArtDirectedDomainsBackground>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="medium">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 500 }}>
              <Container>
                <Section>
                  <Heading size={1} renderAs="h2">
                    Portfolio
                  </Heading>
                  <Columns>
                    <Columns.Column size={4}>
                      {/*<Box
                        style={{
                          position: 'relative',
                          paddingTop: '20rem',
                          backgroundColor: '#3BBFD1'
                        }}
                      >
                        <Image
                          alt="Figleaf"
                          fixed={
                            this.props.data.figleafPart.childImageSharp.fixed
                          }
                          Tag="span"
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '2rem'
                          }}
                        />
                        <Section>
                          <Heading
                            size={4}
                            renderAs="h2"
                            style={{ color: '#fff' }}
                          >
                            Figleaf
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h3"
                            style={{ color: '#fff' }}
                          >
                            User personal privacy protection app ideation and
                            POC development from scratch.
                          </Heading>
                        </Section>
                      </Box>*/}
                      {/*temporary here*/}
                      <Box
                        style={{
                          position: 'relative',
                          paddingTop: '21.5rem',
                          backgroundColor: '#3BBFD1'
                        }}
                      >
                        <Image
                          alt="Viva Street"
                          fixed={
                            this.props.data.vivaStreetPart.childImageSharp.fixed
                          }
                          Tag="span"
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '2rem'
                          }}
                        />
                        <Section>
                          <Heading
                            size={4}
                            renderAs="h2"
                            style={{ color: '#fff' }}
                          >
                            Viva Street
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h3"
                            style={{ color: '#fff' }}
                          >
                            One of the biggest classifieds web sites in France.
                          </Heading>
                        </Section>
                      </Box>
                    </Columns.Column>
                    <Columns.Column size={4} style={{ marginTop: '4rem' }}>
                      <Box
                        style={{
                          position: 'relative',
                          backgroundColor: '#C370D6'
                        }}
                      >
                        <Image
                          alt="Fitness App"
                          fixed={
                            this.props.data.fitnessApp.childImageSharp.fixed
                          }
                          Tag="span"
                          style={{
                            position: 'relative',
                            margin: '0 auto',
                            display: 'flex'
                          }}
                        />
                        <Section>
                          <Heading
                            size={4}
                            renderAs="h2"
                            style={{ color: '#fff' }}
                          >
                            Fitness App
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h3"
                            style={{ color: '#fff' }}
                          >
                            ML-powered fitness app that shows what shape the
                            user can achieve in reality by body photo.
                          </Heading>
                        </Section>
                      </Box>
                    </Columns.Column>
                    <Columns.Column size={4}>
                      <Box
                        style={{
                          position: 'relative',
                          paddingTop: '20rem',
                          backgroundColor: '#5B59FB'
                        }}
                      >
                        <Image
                          alt="Snipper App"
                          fixed={
                            this.props.data.snipperPart.childImageSharp.fixed
                          }
                          Tag="span"
                          style={{
                            position: 'absolute',
                            right: 0,
                            top: '2rem'
                          }}
                        />
                        <Section>
                          <Heading
                            size={4}
                            renderAs="h2"
                            style={{ color: '#fff' }}
                          >
                            Snipper App
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h3"
                            style={{ color: '#fff' }}
                          >
                            SnipperApp is a next-generation code snippets
                            manager, built exclusively for macOS.
                          </Heading>
                        </Section>
                      </Box>
                    </Columns.Column>
                  </Columns>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
        <Hero>
          <Hero.Body>
            <Container>
              <Image alt="Clients" fluid={this.getImage('clientsLogo')} />
              <Image
                alt="Avira"
                style={{
                  position: 'relative',
                  display: 'flex',
                  margin: '0 auto',
                  marginTop: '2rem',
                  opacity: 0.5
                }}
                fixed={this.props.data.aviraLogo.childImageSharp.fixed}
              />
            </Container>
          </Hero.Body>
        </Hero>
        <Hero>
          <Hero.Body className="is-paddingless">
            <Container>
              <ArtDirectedDomainsBackground style={{ padding: '4rem 0' }}>
                <Section>
                  <Columns>
                    <Columns.Column size={5} style={{ paddingTop: '4rem' }}>
                      <Section>
                        <Heading size={1} renderAs="h2">
                          Founders
                        </Heading>
                        <Heading subtitle renderAs="h2">
                          We are passionate about turning any complex project to
                          a simple, state-of-the-art solution
                        </Heading>
                      </Section>
                    </Columns.Column>
                    <Columns.Column offset={1} size={3}>
                      <Box className="is-paddingless">
                        <Image
                          alt="Dmytro"
                          fluid={this.getImage('dmytroImage')}
                          style={{
                            width: '100%',
                            relative: 'relative',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px'
                          }}
                        />
                        <div css={{ padding: '1.25rem' }}>
                          <Heading
                            size={5}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Dmytro
                          </Heading>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '.5rem .5rem 0' }}
                          >
                            Cofounder
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h5"
                            style={{
                              marginTop: '1rem',
                              marginLeft: '.5rem',
                              marginBottom: '.5rem'
                            }}
                          >
                            Product marketing manager and passionate
                            enterpreneur
                          </Heading>
                        </div>
                      </Box>
                    </Columns.Column>
                    <Columns.Column size={3}>
                      <Box className="is-paddingless">
                        <Image
                          alt="Taras"
                          fluid={this.getImage('tarasImage')}
                          style={{
                            width: '100%',
                            relative: 'relative',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px'
                          }}
                        />
                        <div css={{ padding: '1.25rem' }}>
                          <Heading
                            size={5}
                            renderAs="h5"
                            style={{ margin: '1rem .5rem 0' }}
                          >
                            Taras
                          </Heading>
                          <Heading
                            size={6}
                            renderAs="h5"
                            style={{ margin: '.5rem .5rem 0' }}
                          >
                            Cofounder
                          </Heading>
                          <Heading
                            subtitle
                            size={6}
                            renderAs="h5"
                            style={{
                              marginTop: '1rem',
                              marginLeft: '.5rem',
                              marginBottom: '.5rem'
                            }}
                          >
                            Software Engineer with more than 10 years of
                            experience
                          </Heading>
                        </div>
                      </Box>
                    </Columns.Column>
                  </Columns>
                </Section>
              </ArtDirectedDomainsBackground>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      className="custom-section"
                      css={{
                        [Mobile]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        },
                        [Tablet]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          flexDirection: 'inherit'
                        }
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        onClick={this.openMailTo}
                        className="responsive-button"
                        css={{
                          display: 'flex',
                          fontWeight: 'bold',
                          [Mobile]: {
                            marginTop: '2rem'
                          },
                          [Tablet]: {
                            marginTop: 0
                          }
                        }}
                      >
                        GET ESTIMATE
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}
IndexPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}
export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    tarasAvatar: file(absolutePath: { regex: "/tarasAvatar.png/" }) {
      childImageSharp {
        fixed(width: 100, height: 100, quality: 90, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    dimaAvatar: file(absolutePath: { regex: "/dimaAvatar.png/" }) {
      childImageSharp {
        fixed(width: 100, height: 100, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    figleafPart: file(absolutePath: { regex: "/figleaf-part@2x.png/" }) {
      childImageSharp {
        fixed(width: 265, height: 296, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    snipperPart: file(absolutePath: { regex: "/snipper-part@2x.png/" }) {
      childImageSharp {
        fixed(width: 188, height: 260, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    fitnessApp: file(absolutePath: { regex: "/fitness-app-big@2x.png/" }) {
      childImageSharp {
        fixed(width: 244, height: 302, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    dmytroImage: file(absolutePath: { regex: "/dmytro@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tarasImage: file(absolutePath: { regex: "/taras@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    clientsLogo: file(absolutePath: { regex: "/clients-logo-v2@2x.png/" }) {
      childImageSharp {
        fluid(maxWidth: 780, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    aviraLogo: file(
      absolutePath: { regex: "/logo_avira_vertical_FBlack_RGB.png/" }
    ) {
      childImageSharp {
        fixed(width: 150, height: 157, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    vivaStreetPart: file(absolutePath: { regex: "/viva-street-part@2x.png/" }) {
      childImageSharp {
        fixed(width: 287, height: 274, quality: 100, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
